.BottomWrapper {
    position: absolute;
    bottom: 20px;
    left: 20px;
    /* width: calc(100vw - 20px - 20px - 20px - ((100vw - 100px) / 4)); */
    width: calc(100vw - 20px - 20px - 20px - ((100vw - 100px) / 4));
    height: calc(((100vh - 60px - 40px)) / 3);
    display: flex;
    align-content: flex-start;
    z-index: 3;
}

.BottomWrapper>div {
    background: linear-gradient(180deg, rgba(0, 32, 64, 0.8) 0%, rgba(0, 19, 38, 1) 100%);
    /* width: calc((100% - 40px) / 3); */
    width: calc((100%) / 3);
    height: 100%;
    /* border: 1.5px solid rgba(0, 0, 0, 1); */
    display: flex;
    flex-direction: column;
    /* margin-right: 20px; */
}


.BottomWrapper>div:last-child {
    margin-right: 0px !important;
}

/* 标题 */
.BottomWrapper>div>div:nth-child(1) {
    height: 45px;
}

/* 内容 */
.BottomWrapper>div>div:nth-child(2) {
    flex: 1;
}


.oneEchartsWrapper {
    height: 100%;
    width: 100%;
}

.listWra {
    height: 100%;
    overflow-y: auto;
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;
}

.listWra::-webkit-scrollbar {
    width: 0 !important
}

.listlist {
    background: linear-gradient(90deg, rgba(38, 118, 255, 0.15) 0%, rgba(38, 118, 255, 0.01) 100%);
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: rgba(217, 231, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 15px 12px 15px;

}

.listlistDan {
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: rgba(217, 231, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 15px 12px 15px;
}

.listWrachil>div>div:nth-child(1) {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.7);
}

.listWrachil>div>div:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    /* vertical-align: top; */
    padding: 0 1em;
}

.listWrachil>div>div:nth-child(3) {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    /* vertical-align: top; */
    padding: 0 1em;
}

.noDatap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
}

.closeWrapper {
    position: absolute;
    top: -0px;
    left: 47%;
    height: 40px !important;
    width: 30px !important;
    transform: translateY(-40px);
    color: white;
    cursor: pointer;
    z-index: 3;
    background: transparent !important;
    border: none !important;
    display: table-cell !important;
}

.closeWrapper img {
    width: 100%;
    display: table-cell !important;
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
}
