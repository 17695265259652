.LoginForm {
    /* position: absolute; */
    width: 100vw;
    height: 100vh;
    /* background-image: url(./image/background1.jpg); */
    /* background-color: black; */
    /* opacity: 0.85; */
    /* background-size: 100% 100%; */
    /* background-repeat: no-repeat; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.videoWrappermy {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
}

/* 标题 */
.loginTitleWrapper {
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
    background-image: url(./image/sun.png);
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
}

@font-face {
    font-family: "YouSheBiaoTiHei";
    src: url("./image/YouSheBiaoTiHei-2.ttf");
}

.loginTitleWrapper p {
    margin: 0px;
    font-size: 56px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    line-height: 66px;
    /* letter-spacing: 5px; */
    text-shadow: 1px 1px 1px rgba(228, 251, 255, 0.8);
    background: linear-gradient(180deg, #ffffff 27%, #2db9d3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* 左上角logo */
.logoImg {
    position: absolute;
    top: 4vh;
    left: 2vw;
    /* height: 6vh; */
    width: 200px;
    /* width: 300px; */
}

/* 登录表单 */
.loginWrapper {
    margin-left: calc(100vw - 35vw - 10vw);
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 6vh;
    z-index: 1000;
    height: 100vh;
}

.loginTable {
    width: 100%;
    padding: 6vh 4vw 1vh 4vw;
    background: rgba(0, 37, 95, 0.8);
    box-shadow: 0px 0px 12px 1px rgba(119, 233, 255, 0.3),
        0px 0px 6px 1px rgba(119, 233, 255, 0.6);
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    border: 1px solid #227fad;
}

/* .loginTable input:-webkit-autofill {
    box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-text-fill-color: #e71a1a !important;
} */
/* edge浏览器自动填充后，变白 */
.loginTable input:-internal-autofill-previewed,
.loginTable input:-internal-autofill-selected {
    -webkit-text-fill-color: #f7f0f0;
    transition: background-color 5000s ease-out 0.5s;
}

/* 登录按钮 */
.loginFormBtn {
    width: 60%;
    height: 6vh !important;
    margin: 0 auto;
    display: block !important;
    background-color: #05b6ce !important;
    border-radius: 100px !important;
    border: none !important;
    font-size: 3vh !important;
}

/* 用户名密码输入框 */
.formInput {
    font-size: 200%;
    background-color: transparent;
    /* margin-top: 5px; */
    border-bottom: 2px solid white;
    border-top: none;
    border-left: none;
    border-right: none;
    color: white;
}

.formInput>input {
    background-color: transparent !important;
    color: white;
}

.formInput svg {
    color: #bfbfbf;
}

:global(#normalLoginFrm .ant-form-item-has-error .ant-input-affix-wrapper) {
    background-color: transparent;
}

:global(#normalLoginFrm .ant-form-item) {
    margin-bottom: 4vh;
}

:global(#normalLoginFrm .ant-form-item-has-error .ant-input) {
    background-color: transparent;
}

/* 底部公司版权 */
.LoginFooter {
    background-color: #24262a17 !important;
    color: white;
    text-align: center;
    padding: 0;
    height: 30px;
    line-height: 30px;
    position: absolute;
    bottom: 0px;
    width: 100vw;
}

.codeV {
    background-color: rgba(247, 244, 247, 0.5) !important;
}

.wechatWrapperTwo {
    margin-top: 5%;
    color: white;
    text-align: center;
    font-size: 16px;
    display: flex;
    /* flex-direction: column; */
    width: 100%;
}

.wechatWrapperTwo p {
    margin: 0 !important;
}

/* 电话 */
.wechatWrapperTwo>div:nth-child(1) {
    /* width: calc((100% - 9em) / 3);
    padding: 5px 0px 10px 20px; */
    width: fit-content;
    padding: 0 20px;
    font-size: 20px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* 二维码 */
.wechatWrapperTwo>div:nth-child(2) {
    flex: 1;
    display: flex;
}

.wechatWrapperTwo>div:nth-child(2)>div {
    width: calc((100% - 40px)/ 3);
}

.wechatWrapperTwo>div:nth-child(2)>div:nth-child(2) {
    margin: 0 20px;
}

.wechatWrapperTwo>div:nth-child(2)>div>div {
    flex-shrink: 0;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    background: rgba(0, 60, 154, 0.8);
    box-shadow: inset 0px 0px 4px 1px rgba(119, 233, 255, 0.8);
    border-radius: 5px;
    border: 1px solid #126792;
}

.wechatWrapperTwo>div:nth-child(2)>div img {
    width: 100%;
}

/* 
.wechatWrapperTwo>div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    width: 130px !important;
}

.wechatWrapperTwo>div:first-child p {
    font-size: 20px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: #ffffff;
    margin: 0px;
}

.wechatWrapperTwo>div>div {
    width: 100%;
    padding: 5px;
    background: rgba(0, 60, 154, 0.8);
    box-shadow: inset 0px 0px 4px 1px rgba(119, 233, 255, 0.8);
    border-radius: 5px;
    border: 1px solid #126792;
}

.wechatWrapperTwo img {
    width: 100%;
    height: 100%;
} */

.VertifyWraqpper {
    position: absolute;
    /* top: calc((100vh - 160px - 10vh) / 2);
    left: 10vw; */
    background-color: rgba(255, 255, 255, 0.7);
    /* width: 45vh;
    padding: 5vh 0; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.gonggaoWra {
    position: absolute;
    background-color: #05b6ce;
    top: 10vh;
    left: 20vw;
    width: 60vw;
    height: 80vh;
    z-index: 9999;
}

.ggBut {
    width: 100%;
    text-align: right;
}

.ggContent {
    text-indent: 2em;
    font-size: 18px;
}

/* .LoginFooter :global(.ant-modal-content){
    background-color: red !important;

} */

.myModal :global(.ant-modal-content) {
    color: white;
    background: rgba(0, 37, 95, 0.8);
    box-shadow: 0px 0px 12px 1px rgba(119, 233, 255, 0.3),
        0px 0px 6px 1px rgba(119, 233, 255, 0.6);
}

.myModal :global(.ant-modal-header) {
    background: linear-gradient(180deg, rgba(0, 23, 64, 0.6) 0%, rgba(0, 14, 38, 0.8) 100%);
    color: white !important;
    border-bottom: 1.5px solid rgba(206, 205, 205, 0.521);
}

.myModal :global(.ant-modal-header>div) {
    color: white !important;
}