.RetrievePasswordWrapper {
    width: 100vw;
    height: 100vh;
    /* background-image: url(./image/background1.jpg); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* padding: 4vh 5vw 4vh 6vw; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.zhuWrapper {
    /* background-color: rgba(83, 104, 224, 0.658); */
    width: 80vw;
    height: 70vh;
    padding: 10vh 4vw 46vh 4vw;
    border-radius: 10px;
    background-image: linear-gradient(-160deg, rgba(5, 34, 51, 1), rgba(4, 27, 40, 0.03));
    box-shadow: 0px 0px 16px rgba(0, 202, 216, 0.747) inset;
    z-index: 99;
}

.stepWrapper {
    height: 150px;
    display: flex;
    align-items: center;
}

.operstionWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 40px;
}

/* 步骤条 */
/* 正在进行中 */
:global(#passwordSteps>.ant-steps-item-active .ant-steps-item-title) {
    color: white;
}

/* 等待 */
:global(#passwordSteps>.ant-steps-item-wait .ant-steps-item-title) {
    color: rgb(131, 130, 130);
}

/* 结束 */
:global(#passwordSteps>.ant-steps-item-finish .ant-steps-item-title) {
    color: white;
}

.getDynamicCodeNutton {
    margin: 0px;
    cursor: pointer;
}

.yesMima {
    width: 100%;
    text-align: center;
    color: white;
}

.formStyle label {
    color: white;
}

.formInput {
    background-color: transparent;
    color: white;
}

.formInput span {
    background-color: transparent;
    color: white;
}

.formInput input {
    background-color: transparent;
    color: white;
}

.formInput button {
    background-color: transparent;
    color: white;
}

.caseWrapper {
    width: 40%;
}

.successImg {
    margin-bottom: 20px;
    width: 100px;
}

.videoWrappermy {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
}
/* 左上角logo */
.logoImg {
    position: absolute;
    top: 2vh;
    left: 2vw;
    /* height: 6vh; */
    width: 200px;
    /* width: 300px; */
}