.historyFireWrapper {
    height: 100vh;
    width: 100vw;
}

.rightWra {
    height: calc(100vh - 60px - 40px);
    width: calc((100vw - 100px) / 4);
    position: absolute;
    top: calc(60px + 20px);
    right: 20px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(0, 32, 64, 0.8) 0%, rgba(0, 19, 38, 1) 100%);
    /* border: 1.5px solid rgba(0, 0, 0, 1); */
}

.rightWra>div:nth-child(1) {
    height: 40px;
}

.rightWra>div:nth-child(2) {
    flex: 1;
   
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.formWra {
    margin: 10px;
    opacity: 1;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(166, 198, 255, 0.2) 0%, rgba(166, 198, 255, 0.05) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 15px 15px 15px 15px;
}

/* 导出火点 */
.exportWra {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    /* margin-bottom: 20px; */
}

.exportWra>div:nth-child(1) {
    font-size: 18px !important;
}

.tableWra {
    flex: 1;
    overflow-y: auto;
}

/* 翻页 */
.tableWra :global(.ant-pagination) {
    color: white;
}

/* 选中的按钮 */
.tableWra :global(.ant-pagination-item-active) {
    border-radius: 4px;
    background: rgba(38, 147, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

/* 未选中的按钮 */
.tableWra :global(.ant-pagination-item a) {
    color: rgba(38, 147, 255, 1) !important;
}

/* 选中的按钮 */
.tableWra :global(.ant-pagination-item-active a) {
    color: white !important;
}

/* 三个点 */
.tableWra :global(.ant-pagination-jump-next span) {
    color: rgba(38, 147, 255, 1) !important;
}

/* 下一页 */
.tableWra :global(.ant-pagination-next span) {
    color: rgba(38, 147, 255, 1) !important;
}

.tableWra :global(.ant-pagination-prev span) {
    color: rgba(38, 147, 255, 1) !important;
}

.tableWra :global(.ant-pagination-disabled span) {
    color: rgb(150, 146, 146) !important;
}

.tableWra :global(.ant-pagination-options .ant-select-selector) {
    border-radius: 4px;
    background: rgba(0, 57, 115, 0.8);
    border: 1px solid rgba(38, 147, 255, 0.6);
    color: rgba(255, 255, 255, 0.7);
}


.exportWra>div:nth-child(1) {
    font-size: 14px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}



:global(#historyFireTableTwo) {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8);

}

/* 表头 */
:global(#historyFireTableTwo th) {
    background-color: transparent;
    margin-top: 2vh;
    font-size: 15px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    /* 
    background: linear-gradient(90deg, rgba(38, 118, 255, 0.15) 0%, rgba(38, 118, 255, 0.01) 100%);
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: rgba(217, 231, 255, 1); */
}

/* 表列 */
:global(#historyFireTableTwo td) {
    padding: 5px 0px;
    /* font-size: 14px; */
    color: rgba(255, 255, 255, 0.8);
    border: none;
    cursor: pointer;
}

/* 表格排序 */
:global(#historyFireTableTwo td.ant-table-column-sort) {
    background-color: transparent;
}

/* 表格选中和鼠标移动 */
:global(#historyFireTableTwo tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td) {
    background-color: transparent;
    /* color: #245464; */
}

/* 无数据*/
:global(#historyFireTableTwo .ant-empty-description) {
    color: white;
}

.historyFireFormRow {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.historyFireFormRow>div {
    width: 49%;
}

.historyFireFormRow>div:nth-child(2) {
    margin-left: 2%;
}

.historyFireFormRow :global(.ant-row) {
    width: 100%;
    margin: 0px;
}

.historyFireFormRow :global(.ant-form-item-control) {
    width: 100%;
}

.historyFireFormRow :global(.ant-select-selector) {
    background-color: rgba(0, 57, 115, 0.8) !important;
    border: 1px solid rgba(38, 147, 255, 0.6) !important;
}

.historyFireFormRow input {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 1);
}

.historyFireForm :global(.ant-row) {
    margin: 0;
    padding: 0;
    width: 100%;
}

.historyFireForm :global(.ant-form-item) {
    margin: 0;
    padding: 0;
    width: 100% !important;
}


.historyFireForm span {
    color: rgba(255, 255, 255, 1) !important;
}

/* .historyFireForm :global(.anticon-calendar:hover) {
    background-color: transparent !important;
    cursor: pointer;
}
.historyFireForm :global(.anticon-calendar) {
    background-color: transparent !important;
    cursor: pointer;
} */

.historyFireForm input {
    color: rgba(255, 255, 255, 1) !important;
}

.historyFireForm :global(.ant-picker) {
    width: 100% !important;
    background-color: rgba(0, 57, 115, 0.8) !important;
    border: 1px solid rgba(38, 147, 255, 0.6) !important;
    color: rgba(255, 255, 255, 1) !important;

}

/* ant-picker ant-picker-range ant-picker-small */
.heiehi {
    opacity: 1;
    background: linear-gradient(90deg, rgba(38, 118, 255, 0.15) 0%, rgba(38, 118, 255, 0.01) 100%);
    border-style: solid;
    /* border-color: rgba(217, 231, 255, 1); */
    /* border: 1px solid rgba(38, 147, 255, 0.6) !important; */
}

.closeWrapper {
    position: absolute;
    left: -0px;
    top: 45%;
    height: 30px !important;
    width: 40px !important;
    transform: translateX(-40px);
    background: transparent !important;
    border: none !important;
    color: white;
    cursor: pointer;
    z-index: 3;
    text-align: right;

}

.closeWrapper img {
    height: 100%;
    position: absolute;
    right: 0;
}


.myRangePicker input {
    text-align: center;
}

.myRangePicker :global(.anticon-close-circle) {
    background-color: rgba(0, 57, 115, 0.8) !important;
}

.myRangePicker :global(.anticon-close-circle:hover) {
    background-color: rgba(0, 57, 115, 0.8) !important;
}

.myRangePicker :global(.anticon-close-circle svg) {
    background-color: rgba(0, 57, 115, 0.8) !important;
    color: rgb(250, 139, 65) !important;
}