.mainWrapper {
    background: -webkit-linear-gradient(left top, #27647a, #00161B);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(bottom right, #1e4f61, #00161B);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(bottom right, #29657a, #00161B);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(to bottom right, #30728a, #00161B);
    /* 标准的语法（必须放在最后） */
    height: 100vh;
    width: 100vw;
    background-image: url(./image/background.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.bottomCopyright {
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100vw;
    /* background-color: #cddaff31; */
    color: #94EBFE;
}

/* 版权信息 */
.LoginFooter {
    background-color: #24262a17 !important;
    color: white;
    text-align: center;
    padding: 0;
    height: 20px;
    line-height: 20px;
    position: absolute;
    bottom: 0px;
    width: 100vw;
    z-index: 2;
}