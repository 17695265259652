.mainDiv {
    height: 100vh;
    width: 100vw;
    background-color: rgb(126, 126, 126);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainDiv>div {
    color: white;
    text-align: center;
    font-size: 25px;
}