.main {
  overflow-y: auto;
  /* padding: 7vh 20px 20px 20px; */
  height: 100vh;
  width: 100vw;
  background-image: url(../Main/image/background.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
.main > div:first-child {
  height: 5vh;
}
.main > div:last-child {
  height: 95vh;
  overflow-y: auto;
  /* padding: 4vh 2vw; */
}
.headerStyle {
  position: absolute;
  top: 0px;
  left: 600px;
  height: 5vh;
  line-height: 5vh;
  display: flex;
}
/* 头 */
.headerWrapper {
    height: 5vh;
    line-height: 5vh;
    padding: 0px 0px 0px 20px;
    background: linear-gradient(to left top, #00161b00, #00161b15, #00161b79);
    /* background-image: linear-gradient(360deg, rgba(5, 34, 51, 1), rgba(4, 27, 40, .36), rgba(2, 15, 23, 1)); */
    display: flex;
    z-index: 10;
    /* 在弹性盒子四周留白 */
    justify-content: space-around;
    width: 100vw;
  }
  
  .headerTitleWrapper {
    height: 5vh;
    /* background-image: url(../../Main/image/mainBack1.png);
    background-repeat: no-repeat;
    background-size: auto 100%; */
    /* background-position: right; */
    width: fit-content;
    padding-right: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .headerTitleWrapper img {
    height: 4vh;
    margin-top: 0.5vh;
    vertical-align: top;
  }
  
  .headerTitle {
    color: #7ee8ff;
    /* font-size: 1.5vw; */
    font-size: 30px;
    line-height: 5vh;
    text-shadow: 0px 2px 4px #24535d, 1px 1px 4px #62d8f1;
    /* width: 35vw; */
    padding-left: 1vw;
  }
  
  .headerRight {
    float: right;
    margin-left: auto;
    background: linear-gradient(to right, #00161b00, #00161bb7, #00161b);
    padding-right: 40px;
    /* width: 28vw; */
    text-align: end;
    display: flex;
    /* width: fit-content; */
  }
  
  .headerTime {
    color: #94ebfe;
    /* margin-left: auto; */
    /* font-size: 1vw; */
    font-size: 20px;
    margin: 0vh 20px;
    text-align: center;
    /* width: 400px; */
    overflow: hidden;
  }
  
  .userIcon {
    height: 3vh;
    width: 3vh;
    margin: 1vh 0vh;
  }
  
  .headerUserTitle {
    font-size: 20px;
    /* font-size: 1vw; */
    color: #94ebfe;
    margin: 0vh 1vw 0vh 0vw;
    cursor: pointer;
  }
  
  .signoutIcon {
    height: 3vh;
    width: 3vh;
    margin: 1vh;
    cursor: pointer;
  }
  
  .headerLinkStyle {
    display: flex;
    justify-content: space-around;
    flex: 1;
  }
  .headerLinkStyle div {
    padding: 0px 2em;
  }
  .headerLinkStyle a {
    color: #7ee8ff;
    font-size: 20px;
  }
  .selectLink {
    background-color: #7ee8ff;
  }
  .selectLink a {
    color: #245464;
  }
  