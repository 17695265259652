.rightSiderWrapper {
    position: absolute;
    /* display: flex; */
    /* flex-wrap: nowrap; */
    /* justify-content: space-between; */
    color: #fff;
    width: 470px;
    height: 390px;
    /* width: 30vw;
    height: 40vh; */
    /* padding: 2vh 1.5vw; */
    z-index: 10;
    background: linear-gradient(180deg, rgba(0, 23, 64, 0.6) 0%, rgba(0, 14, 38, 0.8) 100%);
    border: 1.5px solid rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;

}

.rightSiderWrapper>div:nth-child(3) {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.rightSiderWrapper>div:nth-child(3)>div:nth-child(2) {
    flex: 1;
    overflow: hidden;
}

/* 火情次数 */
.cardTitle {
    background-image: url(../../common/image/titleBack2.png);
    background-size: 100% 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 4em;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);

}

/* 关闭按钮 */
.closeButton {
    position: absolute;
    right: 1em;
    top: 0;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}


.mypageWrapperer {
    width: 100%;
    display: flex;
    margin-top: 10px;
    padding: 0 1em;
}

/* 左切换 */
.mypage_left {
    width: 32px;
    /* height: 32px; */
    height: 25px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    cursor: pointer;
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;

    border-radius: 4px;
    background: rgba(0, 57, 115, 0.8);
    border: 1px solid rgba(38, 147, 255, 0.6);
    font-size: 28px;
    line-height: 15px;
    text-align: center;
}

.mypage_content {
    flex: 1;
    width: calc(100% - 64px);
}

.mypage_right {
    width: 32px;
    height: 25px;
    border-radius: 4px;
    background: rgba(0, 57, 115, 0.8);
    border: 1px solid rgba(38, 147, 255, 0.6);
    opacity: 1;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    cursor: pointer;
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;
    font-size: 28px;
    line-height: 15px;
    text-align: center;
}

/* 中间按钮 */
.CarouselList {
    width: 72px;
    height: 25px;
}

.CarouselList>div {
    /* background: linear-gradient(180deg, #60a6bf 0%, #3f7095 100%); */
    /* border-radius: 5px 5px 5px 5px; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0 10px;
    height: 100%;
    cursor: pointer;
    border-radius: 4px;
    background: rgba(0, 57, 115, 0.8);
    border: 1px solid rgba(38, 147, 255, 0.6);
}

.CarouselList>div:hover {
    /* background: linear-gradient(180deg, #91e7fa 0%, #63a4c2 100%); */
    background: rgba(38, 147, 255, 1);
}

/* 选中 */
.selectCarouselList {
    background: rgba(38, 147, 255, 1) !important;
    /* background: linear-gradient(180deg, #91e7fa 0%, #63a4c2 100%) !important; */
}

/* 内容卡 ,上面使用flex定义的高度*/
.flexWrapper {
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 1em;
}

/* 左侧TAB列 */
.foreTitleList {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid transparent;
}

.foreTitleList p {
    width: 86px;
    height: 30px;
    opacity: 1;
    border-radius: 4px;
    /* background: linear-gradient(180deg, rgba(54, 111, 168, 1) 25%, rgba(28, 84, 140, 1) 100%); */
    display: flex;
    justify-content: center;
    align-items: center;
}


.checkTabAll {
    cursor: pointer;
}

/* 选中的tab */
.checkedTab {
    /* background: linear-gradient(180deg, rgba(230, 97, 119, 1) 0%, rgba(204, 31, 74, 1) 100%); */
    background: linear-gradient(180deg, rgba(37, 211, 190, 1) 0%, rgba(30, 77, 117, 1) 100%);
}

/* 未选中的tab */
.checkTab {
    background: linear-gradient(180deg, rgba(54, 111, 168, 1) 25%, rgba(28, 84, 140, 1) 100%);

}

/* 右侧 */
.firepopContext {
    flex: 1;
    overflow-y: auto !important;
    padding-left: 1em;
}

/* 复制按钮 */
.copySpan {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copySpan>div {
    border-radius: 4px;
    background: rgba(38, 147, 255, 1);
    width: fit-content;
    cursor: pointer;
    padding: 2px 15px;
}

/* 火点信息列表 */
.firepopDataInfo>div {
    display: flex;
    margin-bottom: 0.5em;
}

.firepopDataInfo>div>span:nth-child(1) {
    width: 6em;
    flex: none;
}

.firepopDataInfo>div>span:nth-child(2) {
    flex: 1;
    border-radius: 2px;
    background: rgba(217, 231, 255, 0.2);
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    padding: 2px 10px 2px 10px;

}

/* 过境卫星列表 */
.statelliWrapper {}

.planP {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 20px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;

}

.jisuanWeixing {
    text-align: center;
}

.jisuanWeixing button {
    background-color: transparent;
    color: white;
    margin-top: 20px;
    border-radius: 4px;
    background: rgba(38, 147, 255, 1);
    border: none;


}

.jisuanWeixing button:hover {
    background: rgba(38, 147, 255, 1);
    color: white;
}

.statelliP {
    margin-bottom: 2vh;
    border-bottom: 1px solid white;
    font-size: 1.6vh;
    padding-left: 1vw;
    padding-right: 1vw;
}






.firepopData {
    color: rgb(255, 255, 255);
    /* margin-top: 2vh; */
    width: 100%;
    height: 100%;
    padding: 6% 0px;
    box-sizing: border-box;
    /* overflow: hidden; */
    overflow-y: auto;
}


.firepopData p {
    margin-bottom: 0px;
    font-size: 1.6vh;
}

.firepopDatButton {
    margin-top: 2vh;
    display: flex;
    justify-content: space-around;
}

.firepopDatButton button {
    border-radius: 5px;
}

/* 核查人表单 */
.verifierForm {
    margin-top: 2vh;
}

.verifierForm label {
    color: rgb(255, 255, 255);
    font-size: 1.6vh;
}

.verifierForm>div>div>div>div>div>div {
    /* width: 30vh !important; */
    background-color: transparent !important;
}

.verifierForm>div>div>div>div>div>div>span {
    color: white;
}

.verifierForm>div>.ant-form-item-control>div {
    width: 30vh !important;
    background-color: transparent !important;
}

.verifierForm>div>div>div>div>div>span {
    color: white;
}

/* 核查照片 */
.imgWrapper {
    display: flex;
}

/* 核查照片 */
.imgClass {
    width: 6vw;
    height: 6vw;
    margin-left: 0.4vw;
    margin-top: 1vh;
}

/* 任务规划 */
.planWrapper {
    width: 100%;
    height: 100%;
    padding: 5% 0px;
    /* overflow: hidden; */
    overflow-y: auto;
}

.planTabsClass {
    color: white;
    background-color: transparent;
}






/* 卫星影像 */
.imgTime {
    /* max-width: 90%;
    max-width: 90%; */
    width: 50%;
    /* height: 50%; */
}

.imgTime2 {
    max-width: 80%;
}

/* 暂无影像 */
.imgTimeP {
    font-size: 1.6vh;
    color: white;
}


:global(#infoWrapper > div > div > div > .ant-tabs-tab) {
    padding: 0 1em !important;
    background-color: transparent !important;
    border-color: #94ebfe !important;
    color: #94ebfe !important;
    border-radius: 10px 10px 1px 1px !important;
    font-size: 1.1em;
}

:global(#infoWrapper > div > div > div > .ant-tabs-tab-active) {
    background-color: #94ebfe !important;
}

:global(#infoWrapper > div > div > div > .ant-tabs-tab-active div) {
    color: #245464 !important;
}

:global(#infoWrapper > .ant-tabs-nav) {
    margin-bottom: 0 !important;
    margin-top: 1em;
}

:global(#infoWrapper > div > .ant-tabs-content) {
    height: 310px !important;
    overflow: auto;
}

:global(#infoWrapper > div > .ant-tabs-nav-operations) {
    color: white;
}

/* ant-pagination-item-1 ant-pagination-item-active */


/* slick-active */
.lonlatWra {
    display: flex;
    justify-content: space-between;
}