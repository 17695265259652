:global(#mainCesium>div>.cesium-viewer>.cesium-viewer-toolbar) {
    top: 50px !important;
}

.addressBox {
    position: absolute;
    left: 20px;
    top: 130px;
    /* color: #fff; */
    /* font-size: 16px; */
    /* padding: 5px; */
    display: flex;
    /* background-image: linear-gradient(-160deg, rgba(5, 34, 51, 1), rgba(4, 27, 40, 0.03)); */
    /* box-shadow: 0px 0px 16px rgba(0, 202, 216, 0.747) inset; */
    line-height: 30px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    color: rgba(37, 103, 168, 1);
}

.address_lon {
    margin-right: 1vw;
}

.myAdminCodeWra {
    position: absolute;
    left: 20px;
    top: 170px;
    /* color: #fff; */
    /* font-size: 16px; */
    /* padding: 5px; */
    display: none;
    padding: 0 2em;
    /* background-image: linear-gradient(-160deg, rgba(5, 34, 51, 1), rgba(4, 27, 40, 0.03)); */
    /* box-shadow: 0px 0px 16px rgba(0, 202, 216, 0.747) inset; */
    line-height: 30px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    color: rgba(37, 103, 168, 1);
    
}