.mainWrapper_use {
  padding: 2vh 2vw 0vh 2vw;
  /* overflow-y: auto; */
  /* height: 100vh; */
}

:global(#userManagerTable) {
  background-color: transparent;
  color: #77e9ff;
  overflow: auto;
}

/* 表头 */
:global(#userManagerTable th) {
  background-color: #94ebfe;
  color: #245464;
  margin-top: 2vh;
  /* font-size: 0.8vw; */
  font-size: 16px;
  border-radius: 4px;
}

/* 表列 */
:global(#userManagerTable td) {
  /* font-size: 0.8vw; */
  font-size: 14px;
  padding: 10px !important;
}

/* 表格排序 */
:global(#userManagerTable td.ant-table-column-sort) {
  background-color: transparent;
}

/* 表格选中和鼠标移动 */
:global(#userManagerTable
    tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td) {
  background-color: #94ebfe98;
  color: #245464;
}
/* 行选中 */
:global(#userManagerTable .ant-table-row-selected td) {
  background-color: #245464;
}

/* 无数据*/
:global(#userManagerTable .ant-empty-description) {
  color: white;
}
/* vip等级 */
:global(#userManagerTable .ant-badge-count) {
  color: white;
  background-color: transparent;
  box-shadow: none;
  color: gold;
}

.mainWrapper_use :global(.ant-pagination) {
  color: white;
}

.mainWrapper_use :global(.ant-pagination-item-ellipsis) {
  color: white !important;
}

.mainWrapper_use :global(.ant-pagination-item-link) {
  background-color: transparent !important;
  color: white;
}

.mainWrapper_use :global(.ant-pagination-item) {
  background-color: transparent !important;
  color: white;
}

.mainWrapper_use :global(.ant-pagination-item > a) {
  color: white;
}

.isVIP {
  /* text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff; */
  color: yellow;
}
.tableOption span {
  font-size: 18px;
}

.topToolWrapper {
  width: 100%;
  text-align: right;
}
