.registerForm {
    width: 100vw;
    height: 100vh;
    /* background-image: url(./image/back6.jpg); */
    /* opacity: 0.85; */
    background-size: 100% auto;
    /* background-repeat: no-repeat; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.registerWrapper {
    width: 35vw;
    display: block;
    border-radius: 10px;
    /* border: 1px solid white; */
    /* background-image: url(../images/login/smallBackground.png); */
    padding: 4vh 2vw;
    background-image: linear-gradient(-160deg,
            rgba(5, 34, 51, 1),
            rgba(4, 27, 40, 0.03));
    border-radius: 10px;
    box-shadow: 0px 0px 16px rgba(0, 202, 216, 0.747) inset;
    z-index: 99;
}

.registerTitleWrapper {
    text-align: center;
    color: white;
    font-size: 3vh;
}

/* 获取动态码的button */
.getDynamicCodeNutton {
    border: none;
    margin: 0;
    padding: 0;
    /* color: rgba(0, 0, 0, 0.671); */
    color: rgba(249, 243, 243, 0.38);
    height: auto;
}

.registerFormBtn {
    width: 40%;
}

.formInput {
    background-color: transparent !important;
    color: white;
}

.formInput input {
    background-color: transparent;
    color: white;
}

.registerWrapper input {
    background-color: transparent !important;
    color: white;
    border-top: none;
    border-left: none;
    border-right: none;
}

.registerWrapper input:hover {
    background-color: transparent !important;
    color: white;
    border-top: none;
    border-left: none;
    border-right: none;
}

.registerWrapper span {
    background-color: transparent !important;
    color: white;
    border-top: none;
    border-left: none;
    border-right: none;
}

.registerWrapper :global(.ant-input-password) {
    background-color: transparent !important;
    border-top: none;
    border-left: none;
    border-right: none;
}

.registerWrapper :global(.ant-select-selection-placeholder) {
    color: #828d93;
}

.registerWrapper :global(.ant-form-item-extra) {
    color: rgba(249, 243, 243, 0.38);
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
}

.registerWrapper :global(.ant-select-selector) {
    color: white;
    background-color: transparent !important;
}

/* 隐私协议 */
.yinsiWra {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.yinsiWra>div:nth-child(1) {
    color: rgb(12, 124, 199);
    cursor: pointer;
    display: flex;
}

.yinsiWra>div:nth-child(2) {
    color: rgba(255, 255, 255, 0.842);
}

.yinsiWra :global(.ant-checkbox-inner) {
    /* border: none !important; */
    border: 1px solid white !important;
}

.videoWrappermy {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
}
/* 左上角logo */
.logoImg {
    position: absolute;
    top: 2vh;
    left: 2vw;
    /* height: 6vh; */
    width: 200px;
    /* width: 300px; */
}