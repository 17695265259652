.mainWra {
    height: 100vh;
    width: 100vw;
    /* padding-top: 60px; */
}

/* 右侧 */
.rightWra {
    height: calc(100vh - 60px - 40px);
    width: calc((100vw - 100px) / 4);
    position: absolute;
    top: calc(60px + 20px);
    right: 20px;
}

.rightWra>div {
    /* 没有间隔 */
    height: calc((100%) / 3);
    /* 有间隔 */
    /* height: calc((100% - 40px) / 3); */
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 32, 64, 0.8) 0%, rgba(0, 19, 38, 1) 100%);
    /* border: 1.5px solid rgba(0, 0, 0, 1); */
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: column;
    /* border: 1px solid white; */
}

.rightWra>div:last-child {
    margin-bottom: 0px;
}

/* 标题 */
.rightWra>div>div:nth-child(1) {
    height: 45px;
}

/* 内容 */
.rightWra>div>div:nth-child(2) {
    flex: 1;
}



/* 今日预警 */
.todayWarnWra {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    width: 100%;
}

.todayWarnList {
    /* background-image: url(../../common/image/todayWarn.png); */
    background-size: 100% 100%;
    width: calc((100% - 4vw) / 3);
    margin-left: 1vw;
    height: calc((100% - 6vh) / 2);
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.todayWarnList>div:nth-child(1) {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    /* vertical-align: top; */
    height: 2.5vh;
    line-height: 2.2vh;
}

.todayWarnList>div:nth-child(2) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);

}

.todayFireWra {
    padding: 10px;
    overflow-y: auto;
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;
    /* border: 1px solid white; */
    margin-bottom: 10px;
}

.todayFireWra::-webkit-scrollbar {
    width: 0 !important
}

.firelistWra {
    /* background: rgba(255, 255, 255, 0.08); */
    display: flex;
    margin-bottom: 10px;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(166, 198, 255, 0.2) 0%, rgba(166, 198, 255, 0.05) 100%);
    overflow: hidden;
}

.firelistLeft {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    min-width: 50%;
    padding: 4px 8px;
}

.rowWra {
    display: flex;
}

.rowWra>div:nth-child(1) {
    width: fit-content;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    vertical-align: top;
}

.rowWra>div:nth-child(2) {
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
}

.rowWraCont {
    display: flex;
}

.rowWraCont>div:nth-child(1) {
    margin-right: 1em;
}

.rowWraCont>div:nth-child(2) {
    flex: 1;
    overflow: hidden;
    flex-wrap: wrap;
}

.rowWraCont>.rowWra>div:nth-child(2) {
    font-weight: 400;
    overflow: hidden;
}

/* 右侧定位 */
.firelistRight {
    cursor: pointer;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 10px;
    /* background-color: rgba(255, 255, 255, 0.08); */
}

.firelistRight img {
    /* animation: name duration timing-function delay iteration-count direction; */
    /* animation: rota 2s infinite linear; */
    animation-duration: 2s;
    animation-name: rota;
    /* 属性定义动画何时开始。 */
    /* animation-delay :2s'; */
    /* 属性定义动画的播放次数。infinite无限次 */
    animation-iteration-count: infinite;
    /* 规定动画的速度曲线。 */
    animation-timing-function: linear;
    /* 属性定义是否应该轮流反向播放动画。normal 	默认值。动画应该正常播放。 alternate 	动画应该轮流反向播放。 */
    animation-direction: alternate;
    /* 保留动画最后效果 */
    /* animation-fill-mode: infinite; */
    /* 
     none //默认值。动画在动画执行之前和之后不会应用动画中的任何样式到目标元素。
     forwards ;//在动画结束后，元素将应用动画结束时属性值。
     backwards ;//初始状态
     both; //动画遵循 forwards 和 backwards 的规则。也就是说，动画会在两个方向上扩展动画属性。（设置延迟时，动画在未开始时运用动画中from或to的样式，在动画结束后也运用最后的样式。）动画遵循 forwards 和 backwards 的规则。也就是说，动画会在两个方向上扩展动画属性。（设置延迟时，动画在未开始时运用动画中from或to的样式，在动画结束后也运用最后的样式。）
     */
}

@keyframes rota {
    0% {
        transform: rotateY(0);
    }

    100% {
        transform: rotateY(180deg);
    }
}

.weatherDataEcharts {
    height: 100%;
    width: 100%;
}

.noDataWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    height: 100%;
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
}



/* 实时火点 */
.realTimeFirePointWrapper {
    position: absolute;
    height: 30vh;
    width: 25vw;
    top: 30vh;
    left: 30vw;
    background: linear-gradient(180deg, rgba(0, 23, 64, 0.6) 0%, rgba(0, 14, 38, 0.8) 100%);
    border: 1.5px solid rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
}

.realTimeFirePointWrapper::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

.realTimeFirePointWrapper::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: skyblue;
    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
}

.realTimeFirePointWrapper::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
    border-radius: 10px;
}

.closeButton {
    display: flex;
    justify-content: space-between;
    background-image: url(../../common/image/titleBack2.png);
    background-size: 100% 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 3em;
    padding-right: 1em;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
}

.closeButton span {
    height: 40px;
    line-height: 40px;
}

.firepopData {
    padding: 1em;
    color: rgba(255, 255, 255, 1);
    flex: 1;
    overflow-y: auto;
    /* margin-bottom: 2em; */
}

.firepopData p {
    margin-bottom: 5px !important;
    /* border-radius: 2px;
    background: rgba(217, 231, 255, 0.2); */
}



.mypageWrapperer {
    width: 100%;
    display: flex;
    margin-top: 10px;
    padding: 0 1em;
}

/* 左切换 */
.mypage_left {
    width: 32px;
    height: 32px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;

    border-radius: 4px;
    background: rgba(0, 57, 115, 0.8);
    border: 1px solid rgba(38, 147, 255, 0.6);
    font-size: 28px;
    line-height: 22px;
    text-align: center;
}

.mypage_content {
    flex: 1;
    width: calc(100% - 64px);
}

.mypage_right {
    color: rgba(255, 255, 255, 1);
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: rgba(0, 57, 115, 0.8);
    border: 1px solid rgba(38, 147, 255, 0.6);
    opacity: 1;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    cursor: pointer;
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;
    font-size: 28px;
    line-height: 22px;
    text-align: center;
}

/* 中间按钮 */
.CarouselList {
    width: 72px;
    height: 32px;
}

.CarouselList>div {
    /* background: linear-gradient(180deg, #60a6bf 0%, #3f7095 100%); */
    /* border-radius: 5px 5px 5px 5px; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0 10px;
    height: 100%;
    cursor: pointer;
    border-radius: 4px;
    background: rgba(0, 57, 115, 0.8);
    border: 1px solid rgba(38, 147, 255, 0.6);
}

.CarouselList>div:hover {
    /* background: linear-gradient(180deg, #91e7fa 0%, #63a4c2 100%); */
    background: rgba(38, 147, 255, 1);
}

/* 选中 */
.selectCarouselList {
    background: rgba(38, 147, 255, 1) !important;
    /* background: linear-gradient(180deg, #91e7fa 0%, #63a4c2 100%) !important; */
}


.closeWrapper {
    position: absolute;
    left: -0px;
    top: 45%;
    height: 30px !important;
    width: 40px !important;
    transform: translateX(-40px);
    background: transparent !important;
    border: none !important;
    color: white;
    cursor: pointer;
    z-index: 3;
    text-align: right;

}

.closeWrapper img {
    height: 100%;
    position: absolute;
    right: 0;
}



/* 今日火点个数22222222222222222222222222222 */
.todayWarnWra1 {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    color: white;
    padding-bottom: 20px;
    /* border: 1px solid rgba(151, 151, 151, 1); */

}

.todayWarnList1 {
    height: calc(100% / 2);
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    /* padding-bottom: 20px;
    border: 1px solid rgba(151, 151, 151, 1); */
}

.todayWarnList1>div:nth-child(2) {
    text-align: center;
    display: flex;
    justify-content: center;
}

.todayWarnList1>div:nth-child(2) div {
    /* background: linear-gradient(270deg, rgba(0, 224, 219, 0.4) 0%, rgba(79, 248, 247, 0.09) 24.36%, rgba(102, 255, 255, 0) 50.35%, rgba(78, 248, 247, 0.1) 72.99%, rgba(0, 224, 219, 0.42) 100%); */
    /* border: 1px solid rgba(151, 151, 151, 1); */
    width: 60%;
    /* margin-bottom: 10px; */
    border-radius: 5px;
}

.todayWarnList1>div:nth-child(1) {
    flex: 1;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.todayWarnList1>div:nth-child(1)>img {
    height: 90%;
}

.zhuan {
    animation: rotation 3s linear infinite;
}

.todayWarnList1>div:nth-child(1)>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

/* 33333333333333333333 */
.todayWarnWra2 {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.todayWarnList2 {
    width: calc(100% / 3);
    height: calc(100% / 2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.todayWarnList2>img {
    max-height: 90%;
    animation: rotation 4s linear infinite;
}

.todayWarnList2>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.todayWarnList2>div>div:nth-child(2) {
    font-weight: 700;
}
.noDatap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
}
