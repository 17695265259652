.rightOptionWrapper {
  padding: 20px;
  position: absolute;
  right: 0px;
  /* top: calc(5vh + 20px); */
  top: 20px;
  height: calc(95vh - 40px);
  overflow-y: auto;
  width: 650px;
  background-color: #245464e7;
  z-index: 1;
}
.rightOptionWrapper :global(.ant-row) {
  margin-bottom: 10px;
}

.rowDiv {
  display: flex;
  justify-content: space-around;
}

.rowDiv > div {
  width: 40%;
}

/* 表格 */
:global(#nationFireTable) {
  background-color: transparent;
  color: #77e9ff;
  overflow: auto;
}

/* 表头 */
:global(#nationFireTable th) {
  background-color: #94ebfe;
  color: #245464;
  margin-top: 2vh;
  /* font-size: 0.8vw; */
  font-size: 16px;
  border-radius: 4px;
  padding: 5px;
}

/* 表列 */
:global(#nationFireTable td) {
  /* font-size: 0.8vw; */
  font-size: 14px;
  padding: 5px 10px !important;
}

/* 表格排序 */
:global(#nationFireTable td.ant-table-column-sort) {
  background-color: transparent;
}

/* 表格选中和鼠标移动 */
:global(#nationFireTable
    tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td) {
  background-color: #94ebfe98;
  color: #245464;
}
:global(#nationFireTable .ant-table-row-selected > td) {
  background-color: #94ebfe98;
  color: #245464;
}

/* 无数据*/
:global(#nationFireTable .ant-empty-description) {
  color: white;
}

.leftTopInfoWrapper {
  position: absolute;
  top: calc(5vh + 20px);
  left: 20px;
  z-index: 10000;
  background-color: #245464e7;
  /* padding: 20px; */
  color: white;
  border-radius: 5px;
  max-width: 500px;
}
.leftTopHeader {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.leftTopHeader span {
  line-height: 40px;
  font-size: 1.5em;
}
.leftTopContent {
  padding: 20px;
}
.leftTopContent > div {
  display: flex;
}
.leftTopContent > div > span:first-child {
  width: 6em;
  padding: 0px 1em 0 0;
  display: block;
  text-align: right;
  color: #7ee8ff;
}
