@font-face {
    font-family: "pang";
    src: url("./image/庞门正道标题体.ttf");
}

.headerWrapper {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 60px;
    line-height: 60px;
    /* padding: 0px 0px 0px 40px; */
    padding: 0px;
    display: flex;
    z-index: 10;
    /* 在弹性盒子四周留白 */
    /* justify-content: space-between; */
    background-image: url(../../common/image/headerBack.png);
    background-size: 100% 100%;
}

.headerTitleWrapper {
    height: 60px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30vw;
    text-align: center;
    display: flex;
    /* justify-content: center; */
    padding-left: 30px;
    align-items: center;
}

.headerTitleWrapper img {
    max-height: 30px;
    margin-right: 1em;
    margin-bottom: 5px;
    /* background-color: aliceblue; */
}

.headerTitle {
    font-size: 1.6vw;
    line-height: 50px;
    text-shadow: 0px 0px 6px 0px rgba(38, 116, 255, 1);
    /** 文本1 */
    font-weight: 400;
    letter-spacing: 4px;
    color: rgba(255, 255, 255, 1);
    font-family: "pang";


}

.linkWra {
    flex: 1;
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
}

.linkWra>a {
    margin: 20px 0 !important;
}

.linkWra>a:hover {
    color: rgba(255, 255, 255, 1);
}

.linkC {
    background-image: url(../../common/image/noSelect.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 2vw;
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    margin-top: 20px;
    height: 30px;
    line-height: 30px !important;
    /* margin-right: 5vw; */
}

.linkCSelect {
    background-image: url(../../common/image/select1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 2vw;
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    margin-top: 20px;
    height: 30px;
    line-height: 30px !important;
    margin-right: 3em;
}

/* 右侧 */
.headerRight {
    padding-right: 40px;
    display: flex;
}

/* 时间 */
.headerTime {
    font-size: 20px;
    text-align: center;
    overflow: hidden;
    color: rgba(217, 231, 255, 1);
}

.userIcon {
    /* height: 3vh;
    width: 3vh;
    margin: 1vh 0vh; */
    height: 20px;
    width: 20px;
    margin-top: 22px;
    margin-left: 1em;
}

.headerUserTitle {
    font-size: 20px;
    margin-right: 1em;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    color: rgba(217, 231, 255, 1);
}

/* 退出登录 */
.signoutWra {
    line-height: 30px;
    height: 30px;
    margin-top: 15px;
    font-weight: 400;
    display: flex;
    cursor: pointer;
}

.signoutWra>div:nth-child(1) {
    /* color: rgba(255, 38, 116, 1); */
    color: rgba(37, 211, 190, 1);
    background: linear-gradient(270deg, rgba(217, 231, 255, 0.6) 0%, rgba(217, 231, 255, 0) 100%);
    padding: 0 0.5em 0 1em;
}

.signoutWra>div:nth-child(2) {
    width: 6px;
    height: 30px;
    background: rgba(217, 231, 255, 0.8);
    margin-left: 6px;
}

/* 去后台 */
.gobackWra {
    color: rgba(217, 231, 255, 1);
    margin-right: 0.5em;
    cursor: pointer;
}

.maragnSelect {
    background-color: transparent !important;
    height: 40px;
    margin-top: 15px;
    margin-left: 1em;
    width: 6em;
}

.maragnSelect :global(.ant-select-selector) {
    background-color: transparent !important;
    color: white;
    padding: 0 !important;
}

.maragnSelect :global(.ant-select-arrow span) {
    background-color: transparent !important;
    color: white;
}











/* .headerTitleWrapper img {
    height: 4vh;
    margin-top: 0.5vh;
    vertical-align: top;
} */










.signoutIcon {
    height: 3vh;
    width: 3vh;
    margin: 1vh;
    cursor: pointer;
}