.toolWrapper {
    position: absolute;
    left: 20px;
    top: calc(60px + 20px);
    /* background-image: linear-gradient(-160deg, rgba(5, 34, 51, 1), rgba(4, 27, 40, 0.03)); */
    /* border-radius: 10px; */
    /* box-shadow: 0px 0px 16px rgba(0, 202, 216, 0.747) inset; */
    /* padding: 1vh 1vw; */
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    /* display: flex; */
    /* justify-content: flex-start;
align-items: flex-start; */
    padding: 5px 5px 5px 5px;

}

.locationButton {
    margin: 0px 2px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(37, 103, 168, 1);
}

.toolImg {
    width: 14px;
    margin-right: 2px;
    margin-bottom: 3px;
}