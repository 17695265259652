.titleWra {
    height: 100%;
    width: 100%;
    /* background-image: url(../../../common/image/titleBack2.png); */
    background-size: 100% 100%;
    padding-left: 3em;
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    /* background: linear-gradient(180deg, rgba(54, 111, 168, 0.8) 0%, rgba(37, 211, 190, 0.4) 100%); */
    /* border: 2px solid rgba(255, 255, 255, 0.212); */
}