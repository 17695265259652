body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: fixed;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* @font-face {
  font-family: "pangMyFont";
  src: url("./庞门正道标题体3.0.ttf");
} */

/* 历史火情的时间查询框 */
.myRangePicker input {
    text-align: center;
}

.myRangePicker .ant-picker-panel-container {
    background-color: rgba(0, 57, 115, 0.8) !important;
    border: 1px solid rgba(38, 147, 255, 0.6) !important;
    color: white;
}

.myRangePicker .ant-picker-panel-container span {
    color: white !important;
}

.myRangePicker .ant-picker-panel-container button {
    color: white !important;
}

.myRangePicker .ant-picker-panel-container th,
.myRangePicker .ant-picker-panel-container td {
    color: white !important;
}

.myRangePicker .ant-picker-panel-container .ant-picker-cell-in-range::before{
    background-color: rgb(34, 93, 151) !important;
}
.myRangePicker .ant-picker-panel-container .ant-picker-cell-range-end::before{
    background-color: rgb(34, 93, 151) !important;
}
.myRangePicker .ant-picker-panel-container .ant-picker-cell-range-start::before{
    background-color: rgb(34, 93, 151) !important;
}


/* 历史火情的查询框 */
.popupClassNameSelect {
    background-color: rgba(0, 57, 115, 0.8) !important;
    border: 1px solid rgba(38, 147, 255, 0.6) !important;
    color: white;
}

.popupClassNameSelect div {
    color: white !important;
}

.popupClassNameSelect .ant-select-item-option-active {
    color: white !important;
    background-color: #17489d !important;
}
.popupClassNameSelect .ant-select-item-option-selected{
    color: white !important;
    background-color: #17489d !important;
}